<template>
  <div class="update auth__wrapper">
    <!-- <div class="auth__password">
      <div class="update__forget-password-title title-h5">
        {{ $t('main.sign_in_to_account') }}
      </div>
      <div class="update__sub-title">
        {{ $t('main.question_about_account') }}
      </div>
      <Button
        class="auth__btn-pass"
        :transparent="true"
        @click.native="$router.push('/auth')"
      >
        {{ $t('main.enter') }}</Button
      >
    </div>
    <div class="auth__form">
      <div class="auth__back">
        <div class="auth__back-content">
          <router-link class="auth__back-group" :to="{ name: 'home' }">
            <span class="auth__back-content auth__back-content">
              <svg-icon name="arrow-exit" />{{ $t('main.back_main') }}</span
            >
          </router-link>
        </div>
        <svg-icon name="arms" />
      </div>
      <div class="auth__block">
        <h2 class="title-h2 create__title">
          {{ $t('main.create_password') }}
        </h2>
        <div class="auth__fields-wrapper">
          <text-field
            id="new-password"
            v-model="newPassword"
            type="password"
            :placeholder="$t('main.new_password')"
            :is-password="true"
          />
          <text-field
            id="confirm-password"
            v-model="confirmPassword"
            type="password"
            :placeholder="$t('main.confirm_password')"
            :is-password="true"
          />
        </div>
        <div class="auth__btn-wrapper">
          <Button
            :is-icon="true"
            class="auth__btn"
            @click.native="createNewPass"
            >{{ $t('main.change_password') }}
          </Button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
    };
  },
  methods: {
    createNewPass() {
      this.$store.dispatch('auth/createNewPassword', {
        newPassword: '123123',
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>
